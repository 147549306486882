<script setup>
import { useNuxtApp } from 'nuxt/app'
import { onBeforeUnmount, onMounted } from 'vue'
import { useAuthStore } from '~/store/auth.js'

const { $Sentry } = useNuxtApp()

const userStore = useAuthStore()

const user = computed(() => userStore.user)

onMounted(() => {
  if ($Sentry && user.value) {
    $Sentry.setUser({
      id: user.value?.app_user_ref,
      username: user.value?.full_name,
      email: user.value?.email,
    })
  }
})

onBeforeUnmount(() => {
  if ($Sentry) {
    $Sentry.setUser(null)
    $Sentry.close()
  }
})

function handleLogout() {
  userStore.logout()
}
</script>

<template>
  <div>
    <proclaim-status />
    <div class="flex h-screen w-screen bg-gray-100">
      <side-menu />
      <main class="relative flex h-full flex-1 flex-col gap-6 overflow-auto">
        <navbar @logout="handleLogout" />
        <section class="px-2 pb-6 md:px-6">
          <slot />
        </section>
      </main>
    </div>
  </div>
</template>
